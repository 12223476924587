.ant-skeleton.ant-skeleton-element {
  width: 33.3%;
  @media (max-width: 768px) {
    width: 100%; /* Set width to 100% for mobile screens */
  }
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-image {
  width: 100%;
  height: 250px;
}

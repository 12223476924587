@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../public/fonts/Montserrat-VariableFont_wght.ttf") format("ttf");
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../public/fonts/Lato-Black.ttf") format("ttf");
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("../public/fonts/Manrope-VariableFont_wght.ttf") format("ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link {
  @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 font-medium;
}

.active {
  @apply bg-blue-100 text-blue-600;
}
